import { createI18n } from 'vue-i18n'
import tw from '../i18n/tw.json'
import cn from '../i18n/cn.json'
import en from '../i18n/en.json'
// import { useUserInfo } from '../stores/useInfo'

export default defineNuxtPlugin((nuxtApp) => {
  // const userInfo = useUserInfo()
  // locale: userInfo.langs || 'tw',
  const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('langs') || 'tw',
    messages: {
      tw,
      cn,
      en
    }
  })

  nuxtApp.vueApp.use(i18n)
})
