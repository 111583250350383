import plugin_vue3_A0OWXRrUgq from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/node_modules/nuxt/dist/app/plugins/payload.client.js";
import runtime_config_9dAVxnlU3J from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/plugins/runtime-config.js";
import plugin_client_i18n_EJtKqFefby from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/plugins/plugin.client.i18n.js";
import plugin_client_piniaPersistedstate_lsUE3yzXjW from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/plugins/plugin.client.piniaPersistedstate.js";
import plugin_client_vuetify_0I9Qb5vn0D from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/plugins/plugin.client.vuetify.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  runtime_config_9dAVxnlU3J,
  plugin_client_i18n_EJtKqFefby,
  plugin_client_piniaPersistedstate_lsUE3yzXjW,
  plugin_client_vuetify_0I9Qb5vn0D
]