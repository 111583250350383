import { default as _91channelUrl_932Zgj9gT0rSMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/chat/[channelUrl].vue?macro=true";
import { default as _403iPlrLjYkeMMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/errorPage/403.vue?macro=true";
import { default as _404ryquww8mLsMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/errorPage/404.vue?macro=true";
import { default as forgetPwd6LEw5q0AXxMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/forgetPwd.vue?macro=true";
import { default as conversationyxvtCdXdIsMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/conversation.vue?macro=true";
import { default as customerCenter3OCPxuIvtSMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/customerCenter.vue?macro=true";
import { default as feedbacko7nFnFM8fVMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/feedback.vue?macro=true";
import { default as historynqOobU6ri3Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/history.vue?macro=true";
import { default as home5OFBo5lYGzMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/home.vue?macro=true";
import { default as profileQesRZ4YPh1Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/profile.vue?macro=true";
import { default as assignPpaOiWJNa9Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/assign.vue?macro=true";
import { default as basicYU5FZIchGkMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/basic.vue?macro=true";
import { default as blacklistR6bBzdZZw4Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/blacklist.vue?macro=true";
import { default as conversationTagxl5tIhA9vhMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/conversationTag.vue?macro=true";
import { default as customerTagOzFNgV4UHoMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/customerTag.vue?macro=true";
import { default as queuexPrS69KDcaMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/queue.vue?macro=true";
import { default as quickReplyON6Gr70gdRMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/quickReply.vue?macro=true";
import { default as rolesreh0OOqz0wMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/roles.vue?macro=true";
import { default as satisfactionpoBuogv4gKMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/satisfaction.vue?macro=true";
import { default as sensitiveBRa8U3YvBBMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/sensitive.vue?macro=true";
import { default as teamfq2Hw0DC67Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/team.vue?macro=true";
import { default as setup9IlNTsIRA5Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/webAccess/setup.vue?macro=true";
import { default as webSw82D4VQLQMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/webAccess/web.vue?macro=true";
import { default as webAccesspAx70IKw3QMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/webAccess.vue?macro=true";
import { default as setupprzdspuARsMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup.vue?macro=true";
import { default as indexHrq3jXODLUMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index.vue?macro=true";
import { default as inviteUserVerifyJwLjwrQC10Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/inviteUserVerify.vue?macro=true";
import { default as loginzEhy49QLGxMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/login.vue?macro=true";
import { default as registery6uybToGZ3Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/register.vue?macro=true";
import { default as registerVerifyWNeqfeXbF4Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/registerVerify.vue?macro=true";
import { default as resetPwdtOtJCXbB7qMeta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/resetPwd.vue?macro=true";
import { default as verifyAmPfjo0iW2Meta } from "/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/verify.vue?macro=true";
export default [
  {
    name: _91channelUrl_932Zgj9gT0rSMeta?.name ?? "chat-channelUrl",
    path: _91channelUrl_932Zgj9gT0rSMeta?.path ?? "/chat/:channelUrl()",
    meta: _91channelUrl_932Zgj9gT0rSMeta || {},
    alias: _91channelUrl_932Zgj9gT0rSMeta?.alias || [],
    redirect: _91channelUrl_932Zgj9gT0rSMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/chat/[channelUrl].vue").then(m => m.default || m)
  },
  {
    name: _403iPlrLjYkeMMeta?.name ?? "errorPage-403",
    path: _403iPlrLjYkeMMeta?.path ?? "/errorPage/403",
    meta: _403iPlrLjYkeMMeta || {},
    alias: _403iPlrLjYkeMMeta?.alias || [],
    redirect: _403iPlrLjYkeMMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/errorPage/403.vue").then(m => m.default || m)
  },
  {
    name: _404ryquww8mLsMeta?.name ?? "errorPage-404",
    path: _404ryquww8mLsMeta?.path ?? "/errorPage/404",
    meta: _404ryquww8mLsMeta || {},
    alias: _404ryquww8mLsMeta?.alias || [],
    redirect: _404ryquww8mLsMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/errorPage/404.vue").then(m => m.default || m)
  },
  {
    name: forgetPwd6LEw5q0AXxMeta?.name ?? "forgetPwd",
    path: forgetPwd6LEw5q0AXxMeta?.path ?? "/forgetPwd",
    meta: forgetPwd6LEw5q0AXxMeta || {},
    alias: forgetPwd6LEw5q0AXxMeta?.alias || [],
    redirect: forgetPwd6LEw5q0AXxMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/forgetPwd.vue").then(m => m.default || m)
  },
  {
    name: indexHrq3jXODLUMeta?.name ?? "index",
    path: indexHrq3jXODLUMeta?.path ?? "/",
    children: [
  {
    name: conversationyxvtCdXdIsMeta?.name ?? "index-conversation",
    path: conversationyxvtCdXdIsMeta?.path ?? "conversation",
    meta: conversationyxvtCdXdIsMeta || {},
    alias: conversationyxvtCdXdIsMeta?.alias || [],
    redirect: conversationyxvtCdXdIsMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/conversation.vue").then(m => m.default || m)
  },
  {
    name: customerCenter3OCPxuIvtSMeta?.name ?? "index-customerCenter",
    path: customerCenter3OCPxuIvtSMeta?.path ?? "customerCenter",
    meta: customerCenter3OCPxuIvtSMeta || {},
    alias: customerCenter3OCPxuIvtSMeta?.alias || [],
    redirect: customerCenter3OCPxuIvtSMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/customerCenter.vue").then(m => m.default || m)
  },
  {
    name: feedbacko7nFnFM8fVMeta?.name ?? "index-feedback",
    path: feedbacko7nFnFM8fVMeta?.path ?? "feedback",
    meta: feedbacko7nFnFM8fVMeta || {},
    alias: feedbacko7nFnFM8fVMeta?.alias || [],
    redirect: feedbacko7nFnFM8fVMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/feedback.vue").then(m => m.default || m)
  },
  {
    name: historynqOobU6ri3Meta?.name ?? "index-history",
    path: historynqOobU6ri3Meta?.path ?? "history",
    meta: historynqOobU6ri3Meta || {},
    alias: historynqOobU6ri3Meta?.alias || [],
    redirect: historynqOobU6ri3Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/history.vue").then(m => m.default || m)
  },
  {
    name: home5OFBo5lYGzMeta?.name ?? "index-home",
    path: home5OFBo5lYGzMeta?.path ?? "home",
    meta: home5OFBo5lYGzMeta || {},
    alias: home5OFBo5lYGzMeta?.alias || [],
    redirect: home5OFBo5lYGzMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/home.vue").then(m => m.default || m)
  },
  {
    name: profileQesRZ4YPh1Meta?.name ?? "index-profile",
    path: profileQesRZ4YPh1Meta?.path ?? "profile",
    meta: profileQesRZ4YPh1Meta || {},
    alias: profileQesRZ4YPh1Meta?.alias || [],
    redirect: profileQesRZ4YPh1Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/profile.vue").then(m => m.default || m)
  },
  {
    name: setupprzdspuARsMeta?.name ?? "index-setup",
    path: setupprzdspuARsMeta?.path ?? "setup",
    children: [
  {
    name: assignPpaOiWJNa9Meta?.name ?? "index-setup-assign",
    path: assignPpaOiWJNa9Meta?.path ?? "assign",
    meta: assignPpaOiWJNa9Meta || {},
    alias: assignPpaOiWJNa9Meta?.alias || [],
    redirect: assignPpaOiWJNa9Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/assign.vue").then(m => m.default || m)
  },
  {
    name: basicYU5FZIchGkMeta?.name ?? "index-setup-basic",
    path: basicYU5FZIchGkMeta?.path ?? "basic",
    meta: basicYU5FZIchGkMeta || {},
    alias: basicYU5FZIchGkMeta?.alias || [],
    redirect: basicYU5FZIchGkMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/basic.vue").then(m => m.default || m)
  },
  {
    name: blacklistR6bBzdZZw4Meta?.name ?? "index-setup-blacklist",
    path: blacklistR6bBzdZZw4Meta?.path ?? "blacklist",
    meta: blacklistR6bBzdZZw4Meta || {},
    alias: blacklistR6bBzdZZw4Meta?.alias || [],
    redirect: blacklistR6bBzdZZw4Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/blacklist.vue").then(m => m.default || m)
  },
  {
    name: conversationTagxl5tIhA9vhMeta?.name ?? "index-setup-conversationTag",
    path: conversationTagxl5tIhA9vhMeta?.path ?? "conversationTag",
    meta: conversationTagxl5tIhA9vhMeta || {},
    alias: conversationTagxl5tIhA9vhMeta?.alias || [],
    redirect: conversationTagxl5tIhA9vhMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/conversationTag.vue").then(m => m.default || m)
  },
  {
    name: customerTagOzFNgV4UHoMeta?.name ?? "index-setup-customerTag",
    path: customerTagOzFNgV4UHoMeta?.path ?? "customerTag",
    meta: customerTagOzFNgV4UHoMeta || {},
    alias: customerTagOzFNgV4UHoMeta?.alias || [],
    redirect: customerTagOzFNgV4UHoMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/customerTag.vue").then(m => m.default || m)
  },
  {
    name: queuexPrS69KDcaMeta?.name ?? "index-setup-queue",
    path: queuexPrS69KDcaMeta?.path ?? "queue",
    meta: queuexPrS69KDcaMeta || {},
    alias: queuexPrS69KDcaMeta?.alias || [],
    redirect: queuexPrS69KDcaMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/queue.vue").then(m => m.default || m)
  },
  {
    name: quickReplyON6Gr70gdRMeta?.name ?? "index-setup-quickReply",
    path: quickReplyON6Gr70gdRMeta?.path ?? "quickReply",
    meta: quickReplyON6Gr70gdRMeta || {},
    alias: quickReplyON6Gr70gdRMeta?.alias || [],
    redirect: quickReplyON6Gr70gdRMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/quickReply.vue").then(m => m.default || m)
  },
  {
    name: rolesreh0OOqz0wMeta?.name ?? "index-setup-roles",
    path: rolesreh0OOqz0wMeta?.path ?? "roles",
    meta: rolesreh0OOqz0wMeta || {},
    alias: rolesreh0OOqz0wMeta?.alias || [],
    redirect: rolesreh0OOqz0wMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/roles.vue").then(m => m.default || m)
  },
  {
    name: satisfactionpoBuogv4gKMeta?.name ?? "index-setup-satisfaction",
    path: satisfactionpoBuogv4gKMeta?.path ?? "satisfaction",
    meta: satisfactionpoBuogv4gKMeta || {},
    alias: satisfactionpoBuogv4gKMeta?.alias || [],
    redirect: satisfactionpoBuogv4gKMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/satisfaction.vue").then(m => m.default || m)
  },
  {
    name: sensitiveBRa8U3YvBBMeta?.name ?? "index-setup-sensitive",
    path: sensitiveBRa8U3YvBBMeta?.path ?? "sensitive",
    meta: sensitiveBRa8U3YvBBMeta || {},
    alias: sensitiveBRa8U3YvBBMeta?.alias || [],
    redirect: sensitiveBRa8U3YvBBMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/sensitive.vue").then(m => m.default || m)
  },
  {
    name: teamfq2Hw0DC67Meta?.name ?? "index-setup-team",
    path: teamfq2Hw0DC67Meta?.path ?? "team",
    meta: teamfq2Hw0DC67Meta || {},
    alias: teamfq2Hw0DC67Meta?.alias || [],
    redirect: teamfq2Hw0DC67Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/team.vue").then(m => m.default || m)
  },
  {
    name: webAccesspAx70IKw3QMeta?.name ?? "index-setup-webAccess",
    path: webAccesspAx70IKw3QMeta?.path ?? "webAccess",
    children: [
  {
    name: setup9IlNTsIRA5Meta?.name ?? "index-setup-webAccess-setup",
    path: setup9IlNTsIRA5Meta?.path ?? "setup",
    meta: setup9IlNTsIRA5Meta || {},
    alias: setup9IlNTsIRA5Meta?.alias || [],
    redirect: setup9IlNTsIRA5Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/webAccess/setup.vue").then(m => m.default || m)
  },
  {
    name: webSw82D4VQLQMeta?.name ?? "index-setup-webAccess-web",
    path: webSw82D4VQLQMeta?.path ?? "web",
    meta: webSw82D4VQLQMeta || {},
    alias: webSw82D4VQLQMeta?.alias || [],
    redirect: webSw82D4VQLQMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/webAccess/web.vue").then(m => m.default || m)
  }
],
    meta: webAccesspAx70IKw3QMeta || {},
    alias: webAccesspAx70IKw3QMeta?.alias || [],
    redirect: webAccesspAx70IKw3QMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup/webAccess.vue").then(m => m.default || m)
  }
],
    meta: setupprzdspuARsMeta || {},
    alias: setupprzdspuARsMeta?.alias || [],
    redirect: setupprzdspuARsMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index/setup.vue").then(m => m.default || m)
  }
],
    meta: indexHrq3jXODLUMeta || {},
    alias: indexHrq3jXODLUMeta?.alias || [],
    redirect: indexHrq3jXODLUMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inviteUserVerifyJwLjwrQC10Meta?.name ?? "inviteUserVerify",
    path: inviteUserVerifyJwLjwrQC10Meta?.path ?? "/inviteUserVerify",
    meta: inviteUserVerifyJwLjwrQC10Meta || {},
    alias: inviteUserVerifyJwLjwrQC10Meta?.alias || [],
    redirect: inviteUserVerifyJwLjwrQC10Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/inviteUserVerify.vue").then(m => m.default || m)
  },
  {
    name: loginzEhy49QLGxMeta?.name ?? "login",
    path: loginzEhy49QLGxMeta?.path ?? "/login",
    meta: loginzEhy49QLGxMeta || {},
    alias: loginzEhy49QLGxMeta?.alias || [],
    redirect: loginzEhy49QLGxMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registery6uybToGZ3Meta?.name ?? "register",
    path: registery6uybToGZ3Meta?.path ?? "/register",
    meta: registery6uybToGZ3Meta || {},
    alias: registery6uybToGZ3Meta?.alias || [],
    redirect: registery6uybToGZ3Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerVerifyWNeqfeXbF4Meta?.name ?? "registerVerify",
    path: registerVerifyWNeqfeXbF4Meta?.path ?? "/registerVerify",
    meta: registerVerifyWNeqfeXbF4Meta || {},
    alias: registerVerifyWNeqfeXbF4Meta?.alias || [],
    redirect: registerVerifyWNeqfeXbF4Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/registerVerify.vue").then(m => m.default || m)
  },
  {
    name: resetPwdtOtJCXbB7qMeta?.name ?? "resetPwd",
    path: resetPwdtOtJCXbB7qMeta?.path ?? "/resetPwd",
    meta: resetPwdtOtJCXbB7qMeta || {},
    alias: resetPwdtOtJCXbB7qMeta?.alias || [],
    redirect: resetPwdtOtJCXbB7qMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/resetPwd.vue").then(m => m.default || m)
  },
  {
    name: verifyAmPfjo0iW2Meta?.name ?? "verify",
    path: verifyAmPfjo0iW2Meta?.path ?? "/verify",
    meta: verifyAmPfjo0iW2Meta || {},
    alias: verifyAmPfjo0iW2Meta?.alias || [],
    redirect: verifyAmPfjo0iW2Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/1qYBpDGP/0/im2.0/aican/pages/verify.vue").then(m => m.default || m)
  }
]